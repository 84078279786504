import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = (): JSX.Element => (
  <Layout seo={{ title: '存在しないページ' }}>
    <div className="container">
      <h1 className="title">存在しないページ</h1>
      <p>ページが見つかりません。</p>
    </div>
  </Layout>
);

export default NotFoundPage;
